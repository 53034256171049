import Headroom from "headroom.js";
import AOS from "aos";
import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

document.addEventListener("DOMContentLoaded", () => {
  const navbar = document.getElementById("navbar");
  const stickyHeaders = document.querySelectorAll("thead th");
  const table = document.querySelector("table");

  if (navbar) {
    const headroom = new Headroom(navbar, {
      offset: 100,
      tolerance: 5,
      classes: {
        initial: "animated",
        pinned: "slideDown",
        unpinned: "slideUp",
      },
      onPin: () => {
        stickyHeaders.forEach((header) => {
          if (header.getBoundingClientRect().top <= 0) {
            (header as HTMLElement).style.transform =
              `translateY(${navbar.offsetHeight}px)`;
          }
        });
      },
      onUnpin: () => {
        stickyHeaders.forEach((header) => {
          (header as HTMLElement).style.transform = "translateY(0)";
        });
      },
    });

    headroom.init();
  }

  if (table) {
    window.addEventListener("scroll", () => {
      const tableRect = table.getBoundingClientRect();
      if (tableRect.top > 0) {
        stickyHeaders.forEach((header) => {
          (header as HTMLElement).style.transform = "translateY(0)";
        });
      }
    });
  }

  // if #icon-slider exists, initialize Splide
  if (document.getElementById("icon-slider")) {
    new Splide("#icon-slider", {
      type: "loop",
      drag: "free",
      focus: "center",
      arrows: false,
      pagination: false,
      fixedWidth: 100,
      gap: "45px",
      autoScroll: {
        speed: -0.25,
        pauseOnHover: false,
      },
    }).mount({ AutoScroll });
  }

  if (document.getElementById("icon-slider2")) {
    new Splide("#icon-slider2", {
      type: "loop",
      drag: "free",
      focus: "center",
      arrows: false,
      pagination: false,
      fixedWidth: 100,
      gap: "45px",
      autoScroll: {
        speed: 0.25,
        pauseOnHover: false,
      },
    }).mount({ AutoScroll });
  }
});

// JavaScript to toggle mobile menu
document
  .getElementById("mobile-menu-button")
  ?.addEventListener("click", function () {
    const mobileMenu = document.getElementById("mobile-menu");
    if (mobileMenu) {
      mobileMenu.classList.toggle("hidden");
    }
  });

document
  .getElementById("close-mobile-menu")
  ?.addEventListener("click", function () {
    const mobileMenu = document.getElementById("mobile-menu");
    if (mobileMenu) {
      mobileMenu.classList.add("hidden");
    }
  });

// Initialize AOS
AOS.init();
